<template>
  <div class="skeleton-profile-personal">
    <skeleton-preloader-profile-layout>
      <template #desktop>
        <div class="skeleton-profile-personal__personal-form">
          <div
            class="skeleton-profile-personal__personal-form__item"
            v-for="n in FORM_INPUTS_COUNT"
            :key="n"
          >
            <Skeletor class="skeleton-profile-personal__personal-form__label" as="div" />
            <Skeletor class="skeleton-profile-personal__personal-form__input" as="div" />
          </div>
        </div>
      </template>
    </skeleton-preloader-profile-layout>
  </div>
</template>

<script setup lang="ts">
  import { Skeletor } from 'vue-skeletor';

  const FORM_INPUTS_COUNT = 10;
</script>

<style src="~/assets/styles/components/skeleton-preloader/profile-personal-page.scss" lang="scss" />
